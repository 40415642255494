.root {
  background-color: #11284b;
  background-size: cover;
  background-position: center;
  background-image: linear-gradient(250deg, rgba(130, 201, 30, 0) 0%, #062343 85%),
    url(https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Flegacypower.com%2Fwp-content%2Fuploads%2Fsites%2F2%2F2015%2F02%2FMt-Rainier-Banner.jpg&f=1&nofb=1&ipt=c8e31a366b7ab71844550c6c392033d0dc8557ae0207788c90d544d96244b5ae&ipo=images);
  padding-top: calc(var(--mantine-spacing-xl) * 3);
  padding-bottom: calc(var(--mantine-spacing-xl) * 3);
}

.inner {
  display: flex;
  justify-content: space-between;

  @media (max-width: $mantine-breakpoint-md) {
    flex-direction: column;
  }
}

.image {
  @media (max-width: $mantine-breakpoint-md) {
    display: none;
  }
}

.content {
  padding-top: calc(var(--mantine-spacing-xl) * 2);
  padding-bottom: calc(var(--mantine-spacing-xl) * 2);
  margin-right: calc(var(--mantine-spacing-xl) * 3);

  @media (max-width: $mantine-breakpoint-md) {
    margin-right: 0;
  }
}

.title {
  color: var(--mantine-color-white);
  font-family:
    Greycliff CF,
    var(--mantine-font-family);
  font-weight: 900;
  line-height: 1.05;
  max-width: rem(500px);
  font-size: rem(48px);

  @media (max-width: $mantine-breakpoint-md) {
    max-width: 100%;
    font-size: rem(34px);
    line-height: 1.15;
  }
}

.description {
  color: var(--mantine-color-white);
  font-weight: bold;
    max-width: rem(500px);

  @media (max-width: $mantine-breakpoint-md) {
    max-width: 100%;
  }
}

.control {
  padding-left: rem(50px);
  padding-right: rem(50px);
  font-family:
    Greycliff CF,
    var(--mantine-font-family);
  font-size: rem(22px);

  @media (max-width: $mantine-breakpoint-md) {
    width: 100%;
  }
}